import "@/scss/styles.scss";
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../.sourceflow/metadata.json";
import { AppHeader } from "@/components/AppHeader";
import { lazy } from "react";

const AppFooter = lazy(() => import("@/components/AppFooter"));

export default function App({ Component, pageProps }) {
  return (
    <>
      <SourceFlowHead metaObject={metaObject} addTracker={true} cookieExpiryTime={3000}>
        {pageProps?.meta?.title && <title>{pageProps.meta.title}</title>}
      </SourceFlowHead>
      <AppHeader />
      <main className="flex-grow-1">
        <Component {...pageProps} />
      </main>
      <AppFooter />
    </>
  );
}
