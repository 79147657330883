import clsx from "classnames";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from "@/assets/Logo.svg";
import { getRoute } from "@/routes";
import { getGlobal } from "@/getters/getGlobal";
import { DynamicText } from "@/components/DynamicText";
import Phone from "@/assets/Phone.svg";
import Email from "@/assets/Email.svg";
import classes from "./styles.module.scss";
import { useState } from "react";
import { MainMenu } from "@/components/MainMenu";

export const AppHeader = ({ className }) => {
  const global = getGlobal();
  const [show, setShow] = useState(false);

  return (
    <>
      <Navbar expand={false} className={clsx(className, classes.header, "py-md-3")}>
        <Container fluid="xxl" className="d-flex flex-nowrap">
          <Navbar.Brand href="/" className="py-0 me-1" aria-label="Home">
            <Logo className={classes.header__logo} />
          </Navbar.Brand>
          <Nav className={clsx(classes.header__nav, "ms-md-auto me-md-5")}>
            <Nav.Item className="d-flex">
              <Nav.Link href={getRoute("jobs")}>Latest Jobs</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-none d-md-flex">
              <Nav.Link href={getRoute("form.cv")}>Upload CV</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex">
              <Nav.Link href={getRoute("hiring")}>Hiring Talent</Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav className={classes.header__buttons}>
            <Nav.Item className={clsx(classes.header__email, "d-lg-none")}>
              <Nav.Link href={`mailto:${global["contact.details.email"]}`}>
                <Email />
                <DynamicText className="d-none d-lg-inline" path="contact.details.email" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={classes.header__phone}>
              <Nav.Link href={`tel:${global["contact.details.phone"]}`}>
                <Phone />
                <DynamicText className="d-none d-lg-inline" path="contact.details.phone" />
              </Nav.Link>
            </Nav.Item>
            <Navbar.Toggle onClick={() => setShow(true)} className={clsx(classes.header__toggle, { show })}>
              <span />
              <span />
              <span />
            </Navbar.Toggle>
          </Nav>
        </Container>
      </Navbar>
      <MainMenu show={show} setShow={setShow} />
    </>
  );
};
