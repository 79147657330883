import clsx from "classnames";
import LinkedIn from "@/assets/LinkedIn.svg";
// import Facebook from "@/assets/Facebook.svg";
// import X from "@/assets/X.svg";
// import Instagram from "@/assets/Instagram.svg";
import classes from "./styles.module.scss";
import { getGlobal } from "@/getters/getGlobal";

export const ConnectWithUs = ({ className, title = "Connect with us" }) => {
  const global = getGlobal();

  return (
    <div className={clsx(className, classes.connect, "justify-content-md-center mb-3 mb-md-0")}>
      <b>{title}</b>
      <a href={global["contact.details.linkedin"]} target="_blank" aria-label="Linkedin">
        <LinkedIn />
      </a>
      {/*<a href={global["contact.details.facebook"]} target="_blank" aria-label="Facebook">*/}
      {/*  <Facebook />*/}
      {/*</a>*/}
      {/*<a href={global["contact.details.twitter"]} target="_blank" aria-label="Twitter">*/}
      {/*  <X />*/}
      {/*</a>*/}
      {/*<a href={global["contact.details.instagram"]} target="_blank" aria-label="Instagram">*/}
      {/*  <Instagram />*/}
      {/*</a>*/}
    </div>
  );
};
