export const routes = {
  home: "/",
  about: "/about-us/",
  candidate: "/candidate/",
  "candidate.faq": "/candidate/contract-faqs/",
  "case.studies": "/case-studies/",
  "case.studies.item": "/case-studies/[url_slug]/",
  community: "/community/",
  "community.blogs": "/community/blogs/",
  "community.blogs.item": "/community/blogs/[url_slug]/",
  "community.news": "/community/news/",
  "community.news.item": "/community/news/[url_slug]/",
  "community.podcasts": "/community/podcasts/",
  "community.podcasts.item": "/community/podcasts/[url_slug]/",
  "community.videos": "/community/videos/",
  "community.videos.item": "/community/videos/[url_slug]/",
  contact: "/contact-us/",
  expertise: "/expertise/",
  "expertise.item": "/expertise/[url_slug]/",
  hiring: "/hiring-talent/",
  "hiring.faqs": "/hiring-talent/employer-faqs/",
  "hiring.item": "/hiring-talent/[url_slug]/",
  jobs: "/jobs/",
  team: "/about-us/meet-the-team/",
  "team.item": "/about-us/meet-the-team/[url_slug]/",
  "policy.privacy": "/privacy-policy/",
  "policy.terms": "/terms-and-conditions/",
  "form.cv": "/upload-cv/",
  "form.resource-requirement": "/hiring-talent/resource-requirement/",
  "form.options-appraisal": "/options-appraisal/",
  "form.discovery-call": "/candidate/discovery-call/",
  "form.complete": "/submission-complete/",
  "contact.thankyou": "/contact-thank-you",
  "candidate.thankyou": "/candidate-thank-you",
  "resource.thankyou": "/resource-thank-you",
  "appraisal.thankyou": "/appraisal-thank-you",
  "upload.thankyou": "/upload-thank-you",
  "applied.thankyou": "/applied-for-a-job",
};

export const getRoute = (key, url_slug = null) => {
  return url_slug ? routes[key].replace("[url_slug]", url_slug) : routes[key];
};
