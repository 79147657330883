import { Button, Collapse, Nav, Offcanvas, Stack } from "react-bootstrap";
import { getMainMenu } from "@/getters/getMainMenu";
import clsx from "classnames";
import Chevron from "./Chevron.svg";
import Close from "./Close.svg";
import classes from "./styles.module.scss";
import { Fragment, useEffect, useState } from "react";
import Logo from "@/assets/Logo.svg";
import { ConnectWithUs } from "@/components/ConnectWithUs";

export const MainMenu = ({ className, show, setShow }) => {
  const items = getMainMenu();
  const [state, setState] = useState(
    items.reduce((acc, i) => ({ ...acc, [i.id]: "open" in i && i.open === true }), {})
  );

  const isActive = (url) => {
    if (typeof window !== "undefined") {
      return window.location.pathname === url;
    }

    return false;
  };

  return (
    <Offcanvas placement="end" onHide={() => setShow(false)} className={clsx(className, classes.menu)} show={show}>
      <Stack className="flex-row-reverse">
        <div className="d-flex flex-column flex-grow-1 p-3">
          <Logo width="155" height="65" style={{ fill: "white" }} className="mb-5" />
          <Nav className="flex-column flex-grow-1 mb-5">
            {items
              .filter((i) => i.enabled)
              .map((i) => (
                <Fragment key={i.id}>
                  {i.children.length > 0 ? (
                    <>
                      <Nav.Item className={clsx({ open: i.id in state ? state[i.id] : false })}>
                        {i.url ? (
                          <Nav.Link href={i.url} active={isActive(i.url)}>
                            {i.title}
                          </Nav.Link>
                        ) : (
                          <Nav.Link onClick={() => setState({ ...state, [i.id]: !state[i.id] })}>{i.title}</Nav.Link>
                        )}
                        <Button onClick={() => setState({ ...state, [i.id]: !state[i.id] })}>
                          <Chevron />
                        </Button>
                      </Nav.Item>
                      <Collapse in={i.id in state ? state[i.id] : false} className="px-3">
                        <Nav className="flex-column flex-nowrap">
                          {i.children.map((c) => (
                            <Nav.Item key={c.id}>
                              <Nav.Link active={isActive(c.url)} href={c.url}>
                                {c.title}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </Collapse>
                    </>
                  ) : (
                    <Nav.Item>
                      <Nav.Link href={i.url} active={isActive(i.url)}>
                        {i.title}
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Fragment>
              ))}
          </Nav>
          <ConnectWithUs title="Connect" className={clsx(classes.menu__connect, "mb-4 mb-md-4 px-3")} />
        </div>
        <Button className={classes.menu__close} onClick={() => setShow(false)}>
          <Close />
          <span>Close</span>
        </Button>
      </Stack>
    </Offcanvas>
  );
};
